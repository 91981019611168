import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function ChildLegacy() {
  const { childNumber } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/child/${childNumber}`);
  }, []);

  return <>Budete přesměrování na novou url...</>;
}
