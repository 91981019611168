import React, { useState } from 'react';
import { IEmailItem } from './EmailQueue';
import { Button, Modal } from 'react-bootstrap';

export default function EmailQueueDetail(props: {
  item: IEmailItem;
  bigger?: boolean;
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Otevřít
      </Button>

      <Modal
        show={show}
        className={props.bigger ? 'modal-lg' : ''}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.item.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <strong>Vytvořeno:</strong> {props.item.createdAt}
            <br />
            <strong>Komu:</strong> {props.item.email}
            <br />
            <strong>Typ:</strong> {props.item.type}
            <br />
            <strong>Předmět:</strong> {props.item.subject}
            <br />
            <strong>Stav:</strong> {props.item.status}
            <br />
            {props.item.sentAt && (
              <>
                <strong>Odesláno:</strong> {props.item.sentAt}
                <br />
              </>
            )}
            <hr />
            <div
              dangerouslySetInnerHTML={{ __html: props.item.htmlBody }}
            ></div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
