import React from 'react';
import {
  Maybe,
  PaymentIntervalReminder,
  Scalars,
} from '../../__generated__/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';

interface PaymentRequestReminder {
  id: Scalars['ID']['output'];
  intervalReminder: Partial<PaymentIntervalReminder>;
  remindedAt?: Maybe<Scalars['DateTime']['output']>;
  reminderDate: Scalars['DateTime']['output'];
}

export default function RemindersTable(props: {
  intervalReminders: Partial<PaymentIntervalReminder>[];
  paymentReminders: Partial<PaymentRequestReminder>[];
}) {
  const intervalReminders = [...props.intervalReminders].reverse();

  const getPaymentReminder = (
    reminder: Partial<PaymentIntervalReminder> | undefined,
  ): Partial<PaymentRequestReminder> | undefined => {
    if (!reminder) {
      return undefined;
    }
    return props.paymentReminders.find(
      (paymentReminder) => paymentReminder.intervalReminder?.id === reminder.id,
    );
  };

  return (
    <table className="table table-sm table-bordered pb-0 mb-0">
      <thead>
        <tr>
          {intervalReminders.map((reminder) => (
            <th className="text-center" key={reminder.id}>
              {reminder.daysBefore}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {intervalReminders.map((reminder) => {
            const paymentReminder = getPaymentReminder(reminder);
            return (
              <>
                {paymentReminder ? (
                  <td className="text-center text-success" key={reminder.id}>
                    <FontAwesomeIcon icon={faCheck} />
                  </td>
                ) : (
                  <td className="text-center text-muted" key={reminder.id}>
                    <FontAwesomeIcon icon={faMinus} />
                  </td>
                )}
              </>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
