import { gql, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import Address, { IAddress } from './Address';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { UserContext } from '../../contexts/UserContext';
import Adoptions from './Adoptions';
import { ItemList } from '../../components/common/ItemList';
import { PageDetail } from '../PageDetail';
import UserTransactions from './UserTransactions';
import { Link } from 'react-router-dom';
import Backoffice from '../backoffice/Backoffice';
import PaymentRequests from './PaymentRequests';

interface IResponse {
  myAddresses: { mailingAddress: IAddress; contractAddress: IAddress };
}

const GET_PROFILE = gql`
  query MyAddresses {
    myAddresses {
      mailingAddress {
        addressType
        name
        street
        city
        zip
        state
        phone
      }
      contractAddress {
        addressType
        name
        street
        city
        zip
        state
        phone
        isSameAsMailing
      }
    }
  }
`;

export default function Profile() {
  const { loading, error, data } = useQuery<IResponse>(GET_PROFILE);
  const { role, email, logout } = useContext(UserContext);
  const [key, setKey] = useState<string | null>('home');

  if (loading || !data) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;
  if (key === null) return <p>Chyba</p>;

  const addresses = data.myAddresses;
  if (addresses === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  if (key === 'logout') {
    if (logout) {
      logout();
    }
  }

  const isAdmin = role === 'ADMIN';

  return (
    <>
      <h3>Vítejte</h3>
      <Tabs
        className="nav-fill"
        id="profile-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="home" title="Základní údaje">
          <h4>Základní údaje</h4>
          <ul>
            <ItemList label="E-mail" value={email}></ItemList>
            <li key="change-password">
              <strong>Heslo:</strong> ({' '}
              <Link to="/profile/change-password">změnit</Link> )
            </li>
          </ul>
          <h4>Adresy</h4>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Korespondenční</Accordion.Header>
              <Accordion.Body>
                <Address address={addresses.mailingAddress}></Address>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Smluvní</Accordion.Header>
              <Accordion.Body>
                <Address address={addresses.contractAddress}></Address>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        {isAdmin && (
          <Tab eventKey="backoffice" title="Backoffice">
            <Backoffice />
          </Tab>
        )}
        <Tab eventKey="info" title="Informace">
          <PageDetail slug="info-pro-rodice"></PageDetail>
        </Tab>
        <Tab eventKey="adoptions" title="Vaše adopce">
          <Adoptions></Adoptions>
        </Tab>
        <Tab eventKey="payments" title="Platby">
          <PaymentRequests></PaymentRequests>
        </Tab>
        <Tab eventKey="gifts" title="Potvrzení o daru">
          <UserTransactions></UserTransactions>
        </Tab>
        <Tab eventKey="logout" title="Odhlásit se"></Tab>
      </Tabs>
    </>
  );
}
