import React from 'react';
import { useQuery } from '@apollo/client';
import { Table, Spinner, Alert } from 'react-bootstrap';
import { gql } from '../../__generated__';

const GET_PAYMENT_REQUESTS = gql(`
  query MyPaymentRequests {
    myPaymentRequests {
      id
      adoption {
        child {
          fullNumber
        }
        paymentInterval {
          price
        }
      }
      paid
      paymentDate
    }
  }
`);

const PaymentRequests: React.FC = () => {
  const { loading, error, data } = useQuery(GET_PAYMENT_REQUESTS);

  if (loading)
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  if (error) return <Alert variant="danger">Error: {error.message}</Alert>;

  return (
    <div className="container">
      <h4>Žádosti o platbu</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Datum platby</th>
            <th>Číslo dítěte</th>
            <th>Částka</th>
            <th>Zaplaceno</th>
          </tr>
        </thead>
        <tbody>
          {data?.myPaymentRequests.map((request) => (
            <tr key={request.id}>
              <td>{new Date(request.paymentDate).toLocaleDateString()}</td>
              <td>{request.adoption?.child?.fullNumber}</td>
              <td>{request.adoption?.paymentInterval?.price}&nbsp;Kč</td>
              <td>{request.paid ? 'Ano' : 'Ne'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PaymentRequests;
