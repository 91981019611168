import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Pagination from '../../components/Pagination';
import EmailQueueDetail from './EmailQueueDetail';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

export interface IEmailItem {
  id: string;
  createdAt: string;
  sentAt?: string;
  type: string;
  subject: string;
  htmlBody: string;
  email: string;
  status: string;
}

export interface IEmailItemEdge {
  node: IEmailItem;
}

const GET_EMAIL_QUEUE_LIST = gql`
  query QueuedEmailList($status: String, $email: String) {
    queuedEmailList(status: $status, email: $email) {
      edges {
        node {
          id
          createdAt
          sentAt
          type
          subject
          htmlBody
          email
          status
        }
      }
    }
  }
`;

export default function BackofficeEmailQueueList() {
  const itemsPerPage = 30;

  const { page: currentPage = 1 } = useParams();
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);

  const [filterByEmail, setFilterByEmail] = useState<string | null>(null);
  const [filterByStatus, setFilterByStatus] = useState<string | null>(null);

  const { loading, error, data, refetch } = useQuery(GET_EMAIL_QUEUE_LIST);

  const onChangeFilters = (event: React.MouseEvent) => {
    event.preventDefault();
    refetch({
      status: filterByStatus,
      email: filterByEmail,
    })
      .then((r) => {
        console.log(r);
      })
      .catch((reason) => console.log(reason));
  };

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const queuedEmails = data.queuedEmailList.edges;
  if (queuedEmails === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = queuedEmails.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(queuedEmails.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % queuedEmails.length;
    setItemOffset(newOffset);
    navigate(`/backoffice/email-queue/page/` + (Number(event.selected) + 1));
  };

  return (
    <div>
      <h3>Fronta emailů</h3>

      <div>
        <h6>Filtry</h6>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Dle emailu</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={(e) => setFilterByEmail(e.target.value)}
              placeholder="E-mail"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Dle stavu</Form.Label>
            <Form.Select
              name="status"
              onChange={(e) => setFilterByStatus(e.target.value)}
            >
              <option value="">--</option>
              <option value="CREATED">CREATED</option>
              <option value="PENDING">PENDING</option>
              <option value="SENT">SENT</option>
              <option value="ERROR">ERROR</option>
            </Form.Select>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            onClick={(event) => onChangeFilters(event)}
          >
            Filtrovat
          </Button>
        </Form>
      </div>
      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/backoffice/email-queue`}
      ></Pagination>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Vytvořeno</th>
            <th scope="col">Typ</th>
            <th scope="col">Předmět</th>
            <th scope="col">E-mail</th>
            <th scope="col">Stav</th>
            <th scope="col">Odesláno</th>
            <th scope="col">Akce</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((itemNode: IEmailItemEdge) => (
            <tr key={itemNode.node.id}>
              <th scope="row">{itemNode.node.createdAt}</th>
              <th scope="row">{itemNode.node.type}</th>
              <th scope="row">{itemNode.node.subject}</th>
              <th scope="row">{itemNode.node.email}</th>
              <th scope="row">{itemNode.node.status}</th>
              <th scope="row">{itemNode.node.sentAt}</th>
              <th scope="row">
                <EmailQueueDetail
                  key={itemNode.node.id}
                  item={itemNode.node}
                ></EmailQueueDetail>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/backoffice/email-queue`}
        ></Pagination>
      </div>
    </div>
  );
}
