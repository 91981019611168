import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '../../__generated__';
import { AdoptionLink } from './links/AdoptionLink';
import { ChildNumberLink } from './links/ChildNumberLink';
import EmailQueueDetail from './EmailQueueDetail';

const QUERY = gql(`
  query AdminUpcomingReminders {
    adminUpcomingReminders {
      id
      remindedAt
      reminderDate
      email {
        id
        createdAt
        email
        type
        subject
        status
        sentAt
        htmlBody
      }
      intervalReminder {
        daysBefore
        paymentInterval {
          treaty
        }
      }
      paymentRequest {
        id
        paymentDate
        paid
        adoption {
          id
          child {
            fullNumber
          }
          user {
            email
          }
        }
      }
    }
  }
`);

export const BackofficeReminders = () => {
  const { loading, error, data } = useQuery(QUERY);

  const reminders = data?.adminUpcomingReminders;

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;
  if (reminders === undefined) {
    return <p>Chyba</p>;
  }

  return (
    <>
      <h3>Proběhlé urgence</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Datum urgence</th>
            <th scope="col">Datum platby</th>
            <th scope="col">Email</th>
            <th scope="col">Dítě</th>
            <th scope="col">Adopce</th>
            <th scope="col">Dní předem</th>
            <th scope="col">Zaplaceno</th>
          </tr>
        </thead>
        <tbody>
          {reminders.map((reminder) => (
            <tr key={reminder.id}>
              <td>{new Date(reminder.reminderDate).toLocaleDateString()}</td>
              <td>
                {new Date(
                  reminder.paymentRequest.paymentDate,
                ).toLocaleDateString()}
              </td>
              <td>
                {reminder.email && (
                  <EmailQueueDetail
                    bigger={true}
                    item={reminder.email}
                  ></EmailQueueDetail>
                )}
              </td>
              <td>
                {reminder.paymentRequest.adoption.child && (
                  <ChildNumberLink
                    fullNumber={
                      reminder.paymentRequest.adoption.child.fullNumber
                    }
                  />
                )}
              </td>
              <td>
                <AdoptionLink
                  adoptionId={reminder.paymentRequest.adoption.id}
                />
              </td>
              <td>{reminder.intervalReminder.daysBefore}</td>
              <td>{reminder.paymentRequest.paid ? 'Ano' : 'Ne'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
