import React from 'react';
import { gql } from '../../__generated__';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import RemindersTable from '../../components/common/RemindersTable';

const GET_ADOPTION_DETAILS = gql(`
  query getAdoptionDetails($adoptionId: String!) {
    adminAdoptionDetail(adoptionId: $adoptionId) {
      user {
        email
      }
      child {
        name
        fullNumber
      }
      paymentRequests {
        id
        paymentDate
        paid
        intervalDate {
          paymentInterval {
            price
            intervalReminders {
              id
              daysBefore
            }
          }
        }
        reminders {
          remindedAt
          intervalReminder {
            id
            daysBefore
          }
        }
      }
      paymentInterval {
        treaty
      }
    }
  }
`);

export default function AdoptionDetail() {
  const { adoptionId } = useParams();

  const { loading, error, data } = useQuery(GET_ADOPTION_DETAILS, {
    variables: { adoptionId: adoptionId ?? '' },
  });

  if (loading || !data) return <p>Nahrává se...{adoptionId}</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const adoption = data.adminAdoptionDetail;

  return (
    <div className="page-detail">
      <div className="page-content">
        <div className="container mt-4">
          <h2>Adopce - {adoption.child?.fullNumber}</h2>

          <div className="card mb-3">
            <div className="card-body">
              <h4>Detaily</h4>
              <p>
                <strong>Rodič:</strong>{' '}
                <a
                  style={{ color: 'darkgreen' }}
                  href={`/backoffice/user/${adoption.user?.email}`}
                >
                  {adoption.user?.email}
                </a>
              </p>
              <p>
                <strong>Jméno dítěte:</strong>{' '}
                <a
                  style={{ color: 'darkgreen' }}
                  href={`/child/${adoption.child?.fullNumber}`}
                >
                  {adoption.child?.name} ({adoption.child?.fullNumber})
                </a>
              </p>
              <p>
                <strong>Platební interval:</strong>{' '}
                {adoption.paymentInterval?.treaty}
              </p>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h4>Žádosti o platbu</h4>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Datum žádosti</th>
                    <th>Částka</th>
                    <th>Zaplaceno</th>
                    <th>
                      Notifikace{' '}
                      <span className="fw-normal">[počet dní předem]</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {adoption.paymentRequests?.map((request) => (
                    <tr
                      key={request.id}
                      className={
                        request.paid ? 'table-success' : 'table-danger'
                      }
                    >
                      <td>
                        {new Date(request.paymentDate).toLocaleDateString()}
                      </td>
                      <td>
                        {request.intervalDate.paymentInterval.price}&nbsp;Kč
                      </td>
                      <td>{request.paid ? 'Ano' : 'Ne'}</td>
                      <td>
                        <RemindersTable
                          intervalReminders={
                            request.intervalDate.paymentInterval
                              .intervalReminders
                          }
                          paymentReminders={request.reminders}
                        ></RemindersTable>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
