import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserContext } from '../../contexts/UserContext';
import { ErrorMessage } from '@hookform/error-message';
import { Link, useNavigate } from 'react-router-dom';

type Inputs = {
  username: string;
  password: string;
};

export default function ProfileLogin() {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<Inputs>();

  const { isLoggedIn, login } = useContext(UserContext);

  const [message, setMessage] = useState<string | null>(null);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (login) {
      try {
        await login(data.username, data.password);
      } catch (e) {
        console.log(e);
        if (data.username.includes('@')) {
          setMessage('Chybně zadané uživatelské jméno nebo heslo.');
        } else {
          setMessage(
            'Chybně zadané uživatelské jméno nebo heslo. Pro přihlášení doporučujeme použít emailovou adresu.',
          );
        }
        return;
      }
    }
  };

  useEffect(() => {
    setMessage(null);
    reset();
  }, [isLoggedIn]);

  if (isLoggedIn) {
    navigate('/profile');
  }
  return (
    <div className="child detail p-3">
      <h5>Přihlášení</h5>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="container">
          <div className="row">
            <div className="form-group col col-12">
              {message && (
                <div
                  className="alert alert-danger bg-danger text-white"
                  role="alert"
                >
                  {message}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-sm-8 col-lg-5 col-12">
              <Form.Control
                {...register('username', { required: true })}
                type="input"
                name="username"
                autoComplete="email"
                placeholder="E-mail"
                className="mb-2"
              />
              <ErrorMessage errors={errors} name="username" />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-sm-8 col-lg-5 col-12">
              <Form.Control
                {...register('password', { required: true })}
                type="password"
                name="password"
                autoComplete="current-password"
                placeholder="Heslo"
                className="mb-2"
              />
              <ErrorMessage errors={errors} name="password" />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-12">
              <input
                type="submit"
                className="btn btn-primary mb-2"
                value="Přihlásit"
              />
            </div>
          </div>
        </div>
      </form>
      <ul>
        <li>
          <Link to="/profile/registration">Zaregistrovat se</Link>
        </li>
        <li>
          <Link to="/profile/reset-password">Zapomenuté heslo</Link>
        </li>
      </ul>
    </div>
  );
}
