import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  formatChildSex,
  formatChildState,
  lgLicenseKey,
  preparePhotoUrl,
} from '../tools';
import { gql, useQuery } from '@apollo/client';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import 'lightgallery/css/lg-video.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import LightGalleryItem from './common/LightGalleryItem';
import ChildAdoption from './ChildAdoption';
import { Card } from 'react-bootstrap';

const GET_CHILD = gql`
  query child($fullNumber: String!) {
    child(fullNumber: $fullNumber) {
      name
      number
      fullNumber
      mainPhoto
      description
      birthDate
      sex
      top
      state
      preReserved
      motherName
      fatherName
      adoption {
        id
        user {
          email
        }
      }
      school {
        name
      }
      coordinator {
        name
      }
      teaser
      attachmentGroups {
        id
        title
        attachments {
          id
          type
          filename
        }
      }
      storage {
        files {
          name
          path
          link
        }
        directories {
          name
          files {
            name
            path
            link
          }
        }
      }
    }
  }
`;

interface IResponse {
  child: IChildDetail;
}

export interface IChildDetail {
  name: string;
  number: number;
  fullNumber: string;
  mainPhoto: string;
  description: string;
  birthDate: string;
  sex: string;
  top: boolean;
  state: string;
  preReserved: boolean;
  motherName: string;
  fatherName: string;
  inSchoolAfter: string;
  adoption?: {
    id?: string;
    user?: {
      email: string;
    };
  };
  school: {
    name: string;
  };
  coordinator: {
    name: string;
  };
  teaser: string;
  attachmentGroups: IAttachmentGroup[];
  storage: IStorageDirectory;
}

interface IStorageFile {
  name: string;
  path: string;
  link: string;
}

interface IStorageDirectory {
  name: string;
  directories: IStorageDirectory[];
  files: IStorageFile[];
}

export interface IAttachment {
  id: string;
  type: string;
  filename: string;
  rotationAngle: number;
}

interface IAttachmentGroup {
  id: string;
  title: string;
  attachments: IAttachment[];
}

export function ChildDetail() {
  const { childNumber } = useParams();
  const [galleryId, setGalleryId] = useState<string | undefined>(undefined);

  const { loading, error, data } = useQuery<IResponse>(GET_CHILD, {
    variables: { fullNumber: childNumber },
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const child = data?.child;
  if (child === null || child === undefined) {
    return <p>Chyba při načítání dat.</p>;
  }

  if (galleryId === undefined && data?.child.attachmentGroups?.length) {
    setGalleryId(data.child.attachmentGroups[0].id);
  }

  const gallery = data?.child.attachmentGroups?.find(
    (group) => group.id === galleryId,
  );

  const pluralizeFotka = (count: number) => {
    if (count == 0) return 'položek';
    if (count == 1) return 'položka';
    if (count > 1 && count < 5) return 'položky';
    return 'položek';
  };

  return (
    <div className="child detail p-2">
      {child.adoption?.user && (
        <div className="float-end">
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Adopce</Card.Title>
              <Card.Text>
                <strong>Rodič:</strong> {child.adoption.user.email}
              </Card.Text>

              <Card.Text>
                <a
                  className="text-white btn btn-primary"
                  href={`/backoffice/adoption/${child.adoption.id}`}
                >
                  Detail
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      )}
      <h6>
        <Link to={'/child/' + child.fullNumber}>
          {child.name} - {child.fullNumber}
        </Link>
      </h6>

      {child.top && (
        <div
          className="alert alert-danger text-center fw-bold mb-1"
          role="alert"
        >
          Upřednostněná adopce
        </div>
      )}

      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        licenseKey={lgLicenseKey()}
      >
        <a href={preparePhotoUrl(child.mainPhoto)} data-toggle="lightbox">
          <img
            style={{
              float: 'left',
              maxWidth: '200px',
              maxHeight: '200px',
              width: 'auto',
              height: 'auto',
              margin: '3px 5px 3px 0px',
            }}
            src={preparePhotoUrl(child.mainPhoto)}
            alt=""
          />
        </a>
      </LightGallery>
      <p>
        <strong>Číslo dítěte: </strong> {child.fullNumber}
      </p>
      {child.birthDate && (
        <p>
          <strong>Datum narození: </strong> {child.birthDate}
        </p>
      )}
      {child.sex && (
        <p>
          <strong>Pohlaví: </strong> {formatChildSex(child.sex)}
        </p>
      )}
      <p>
        <strong>Popis: </strong>
        <span
          className="align-top"
          dangerouslySetInnerHTML={{
            __html: child.description,
          }}
        ></span>
      </p>
      {child.state && (
        <p>
          <strong>Stav: </strong>{' '}
          {formatChildState(child.state, child.preReserved)}
        </p>
      )}
      {child.motherName && (
        <p>
          <strong>Jméno matky: </strong> {child.motherName}
        </p>
      )}
      {child.fatherName && (
        <p>
          <strong>Jméno otce: </strong> {child.fatherName}
        </p>
      )}
      {child.coordinator && (
        <p>
          <strong>Koordinátor: </strong> {child.coordinator?.name}
        </p>
      )}
      {child.school && (
        <p>
          <strong>Škola: </strong> {child.school?.name}
        </p>
      )}
      {child.inSchoolAfter && (
        <p>
          <strong>Ve škole od: </strong> {child.inSchoolAfter}
        </p>
      )}
      {child.storage && (
        <div>
          <strong>Soubory</strong>
          <ul>
            {child.storage.directories.map((directory) => (
              <li key={directory.name}>
                <FontAwesomeIcon icon={faFolder} />
                <span>&nbsp;{directory.name}</span>
                <ul>
                  {directory.files.map((file) => (
                    <li key={file.path}>
                      <FontAwesomeIcon icon={faFile} />
                      &nbsp;
                      <a href={file.link} target="_blank" rel="noreferrer">
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
            {child.storage.files.map((file) => (
              <li key={file.path}>
                <a href={file.link} target="_blank" rel="noreferrer">
                  {file.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {gallery && (
        <div>
          <strong>Fotogalerie</strong>
          <select
            className="form-select form-select-sm m-2"
            style={{ width: 'auto' }}
            aria-label=".form-select-sm example"
            onChange={(event) => setGalleryId(event.target.value)}
          >
            {child.attachmentGroups?.map((group: IAttachmentGroup) => (
              <option key={group.id} value={group.id}>
                {group.title} &minus; {group.attachments.length}{' '}
                {pluralizeFotka(group.attachments.length)}
              </option>
            ))}
          </select>

          {gallery && (
            <>
              <div className="d-flex flex-wrap">
                <LightGallery
                  speed={500}
                  slideDelay={0}
                  plugins={[lgThumbnail, lgZoom, lgVideo]}
                  licenseKey={lgLicenseKey()}
                >
                  {gallery.attachments?.map((attachment) => (
                    <LightGalleryItem
                      key={attachment.id}
                      attachment={attachment}
                    ></LightGalleryItem>
                  ))}
                </LightGallery>
              </div>
            </>
          )}
        </div>
      )}
      {child.state === 'for_adoption' && !child.preReserved && (
        <ChildAdoption data={child}></ChildAdoption>
      )}
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
