import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const GET_ADOPTION_SUMMARY = gql`
  query AdoptionSummary {
    adoptionSummary {
      for_adoption
      adopted
      reserved
    }
  }
`;

export default function AdoptionSummary() {
  const { loading, error, data } = useQuery(GET_ADOPTION_SUMMARY);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba : {error.message}</p>;

  return (
    <div className="menu-block">
      <h5>Děti</h5>
      <ul>
        <li>
          <Link to="/children/for_adoption">K adoptování</Link> (
          {data.adoptionSummary.for_adoption})
        </li>
        <li>
          <Link to="/children/reserved">Rezervované</Link> (
          {data.adoptionSummary.reserved})
        </li>
        <li>
          <Link to="/children/adopted">Adoptované</Link> (
          {data.adoptionSummary.adopted})
        </li>
      </ul>
    </div>
  );
}
