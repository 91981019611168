import React from 'react';
import { Link } from 'react-router-dom';

export default function Backoffice() {
  return (
    <div className="backoffice">
      <h5>Backoffice</h5>
      <ul>
        <li>
          <Link to="/backoffice/users">Seznam rodičů</Link>
        </li>
        <li>
          <Link to="/backoffice/user-email-search">Výpis emailů</Link>
        </li>
        <li>
          <Link to="/backoffice/email-templates">Šablony emailů</Link>
        </li>
        <li>
          <Link to="/backoffice/email-queue">Fronta emailů</Link>
        </li>
        <li>
          <Link to="/backoffice/transactions">Transakce</Link>
        </li>
        <li>
          <Link to="/backoffice/reminders">Proběhlé urgence</Link>
        </li>
        <li>
          <Link to="/backoffice/bank-certificates">Bankovní certifikáty</Link>
        </li>
        <li>
          <Link to="/backoffice/youtube-upload">Upload YouTube videí</Link>
        </li>
      </ul>
    </div>
  );
}
