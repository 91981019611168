import React from 'react';

export const AdoptionLink = (props: { adoptionId: string }) => {
  return (
    <a
      className="text-white btn btn-primary"
      href={`/backoffice/adoption/${props.adoptionId}`}
    >
      Detail
    </a>
  );
};
