import React from 'react';

export default function SupportUsMenu() {
  return (
    <div className="menu-block">
      <h5>Podpořte nás</h5>
      <ol>
        <li>
          <a href="/children/for_adoption">Adoptujte si dítě na dálku.</a>
        </li>
        <li>
          <a href="/support-us">Pošlete jednorázový finanční příspěvek.</a>
        </li>
      </ol>
    </div>
  );
}
