import React from 'react';

export function SupportUs() {
  return (
    <div className="page-detail">
      <h5>Podpořte nás</h5>
      <div className="page-content">
        <ol>
          <li className="p-2 pt-3" style={{ fontSize: 'larger' }}>
            Adoptujte si <a href="/children/for_adoption">dítě</a> na dálku.
          </li>
          <li className="p-2 pt-3" style={{ fontSize: 'larger' }}>
            Pošlete jednorázový finanční příspěvek.
            <img
              className="m-2"
              style={{ width: 'calc(250px)', float: 'right' }}
              src="/images/qr_platba.png"
              alt="proContact platba"
            />
            <p style={{ fontSize: 'smaller' }} className="pt-2">
              Váš dar pomůže chudým dětem v Guineji získat školní vzdělání,
              konkrétně nyní na začátku školního roku budou vaše dary použity na
              nákup učebnic, jejichž nedostatek je jednou z velkých překážek
              studia chudých dětí. Jedna sada učebnic stojí v přepočtu{' '}
              <strong>1500 Kč</strong>. Přispět můžete libovolně vysokou částku.
              V průběhu školního roku budou dary použity na zdravotní ošetření.
            </p>
            <p style={{ fontSize: 'smaller' }} className="pt-2">
              Dary lze poslat na náš účet u <strong>RB 1953720001/5500</strong>,
              jako variabilní symbol uvádějte <strong>5555</strong>.
            </p>
            <p style={{ fontSize: 'smaller' }} className="pt-2">
              Máte-li zájem o vystavení potvrzení o daru pro daňové účely,
              napište do poznámky převodu vaše jméno a e-mailem na{' '}
              <a href="mailto:info@pro-contact.cz">info@pro-contact.cz</a> nám
              pošlete vaše údaje (jméno a příjmení/název firmy, adresa/sídlo,
              případně IČ).
            </p>
          </li>
        </ol>
        <p>Děkujeme</p>
      </div>
    </div>
  );
}
