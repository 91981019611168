import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import News from '../components/News';

const GET_NEWS_BY_SLUG = gql`
  query NewsSlug($slug: String!) {
    news(slug: $slug) {
      id
      title
      createdAt
      slug
      teaser
      content
      mainPhoto
    }
  }
`;

export function NewsDetail() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_NEWS_BY_SLUG, {
    variables: { slug },
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const news = data.news;
  if (news === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return <>{news && <News data={news} detail={true}></News>}</>;
}
